<template>
  <div class="container">
    <van-nav-bar
      title="联系我们"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>
    <van-cell-group :border="false">
      <div class="kbox"></div>
      <div class="kbox"></div>
      <van-cell center :border="false">
        <template #title>
          <div class="pItem">
            <p class="star">*</p>
            <p class="pItem-text">意见反馈:</p>
          </div>
        </template>
      </van-cell>
      <van-cell :border="false">
        <textarea class="p-textarea"></textarea>
      </van-cell>
      <van-cell center :border="false" title="上传图片"> </van-cell>
      <van-cell>
        <van-uploader multiple :after-read="afterRead" v-model="fileList" />
      </van-cell>
    </van-cell-group>

    <div class="action-button-box">
      <van-button round block type="info" color="#F3C301" @click="uploadIdea"
        >意见反馈</van-button
      >
      <div class="kbox"></div>
      <div class="kbox"></div>
      <van-button
        round
        block
        plain
        type="info"
        color="#F3C301"
        @click="phoneCall(contractPhone)"
        >联系客服</van-button
      >
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import BaseService from "@/api/base";
import { Toast } from "vant";
export default {
  data() {
    return {
      content: "",
      contractPhone: "",
      fileList: [],
      filePathList: [],
    };
  },
  async beforeMount(){
    var params = {
      id: 5
    }
    const resp = await BaseService.block(this, params);
      if (resp.data.code == 10000) {
        console.log(resp.data.data);
        this.contractPhone = resp.data.data.content
      }
  },
  methods: {
    async afterRead(file) {
      let formData = new FormData();
      formData.append("image", file.file);
      const resp = await BaseService.uploadImg(this, formData);
      if (resp.data.code == 10000) {
        console.log(resp.data.data.imgUrl);
        this.filePathList.push({
          url: "https://jsagency.cn/" + resp.data.data.imgUrl,
        });
        this.fileList = this.filePathList;
      }
    },

    async uploadIdea() {
      console.log(this.fileList);
      var filePath = "";
      this.fileList.forEach(function(item) {
        filePath = filePath + item.url + "|";
      });
      console.log(filePath);
      var params = {
        token: this.$cookie.get("token"),
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
        content: this.content,
        img: filePath,
        type: 2,
      };
      const resp = await BaseService.upMyIdea(this, params);
      if (resp.data.code == 10000) {
        Toast("感谢您的反馈,我们会尽快处理!")
      }
    },

    goHome: function() {
      this.$router.push("/");
    },
    goBack: function() {
      this.$router.go(-1);
    },

    phoneCall: function(phone) {
      Dialog.confirm({
        title: "提示",
        message:
          "联系客服可能等待接通时间较长，您可先将问题或意见反馈给我们，我们会进去联系您！",
        messageAlign: "left",
        confirmButtonText: "联系客服",
        cancelButtonText: "选择反馈",
      })
        .then(() => {
          console.log(phone)
          window.location.href = "tel://" + phone;
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #ffffff;
}

.star {
  color: red;
}
.pItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: center;
}

.pItem .pItem-text {
  margin-left: 5px;
  margin-right: 20px;
  font-size: 14px;
}

.pItem .pItem-text-l {
  margin-left: 12px;
  margin-right: 12px;
  font-size: 14px;
}
.pItem .pItem-text-l.gray {
  color: #b3b3b3;
}

.pItem .pItem-text.gray {
  color: #b3b3b3;
}

.p-textarea {
  border: solid 1px #f5f5f5;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  width: 90%;
  margin: 4px 6px;
  height: 80px;
}
.action-button-box {
  margin: 20px 20px;
}
</style>
